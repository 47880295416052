import {
  resourceGoals,
  resourceIndustries,
  resourceTags,
  spotonProducts,
} from "../../constants";

export default {
  pageTitle: "SpotOn Case Study | DeBlaze Grill",
  pageDescription:
    "Learn how an Italian steakhouse in South Hills, Pennsylvania turns tables faster and saves on credit card processing fees with SpotOn.",
  title: "Steak and tech done to perfection",
  subTitle: `How DeBlaze Grill gives better service with SpotOn.`,
  businessName: "DeBlaze Grill",
  industry: "Restaurants & hospitality, casual dining",
  location: "Bridgeville, Pennsylvania",
  products: [
    spotonProducts.RESTAURANT,
    spotonProducts.SERVE,
    spotonProducts.ORDER,
    spotonProducts.ASSIST,
  ],
  description:
    "In 2019, driven by a passion for creating the perfect steak, Doug DeBlasio started his Italian steakhouse—DeBlaze Grill. And with SpotOn under the hood, he keeps his tables turning faster and saves hundreds of dollars in processing fees every day.",
  quoteData: {
    imgName: "deblaze-grill.png",
    quote: `“If I ever need anything, SpotOn is on it. I’m super impressed by how SpotOn gets four or five people involved with my case. I’m just a small restaurant in Pittsburgh, and it was nice to be treated like I was one of the big ones.”`,
    personName: "Doug DeBlasio",
    personTitle: "Owner, DeBlaze",
  },
  goal: `DeBlasio needed a tech solution that could streamline the operations of his small-but-busy steakhouse. He was also looking for ways to reduce expenses and wanted to integrate online ordering into his restaurant.`,
  solution: `SpotOn Restaurant with SpotOn Serve handhelds allowed servers to process orders and close out checks faster. And while SpotOn Order sent online orders straight to the kitchen, SpotOn Assist cash discount program helped him save big on credit card fees.`,
  results: {
    title: `The results`,
    stats: [
      { title: `$6,000`, description: `monthly savings with cash discounts` },
      {
        title: `10 minutes`,
        description: `faster close out times`,
      },
      { title: `20%`, description: `increase in table turns` },
    ],
  },
};

export const relatedSuccessStories = [
  {
    title: "Beer, beef, and baseball",
    imageName: "von-elrods-beer-hall-and-kitchen.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-02-21",
    href: "/case-studies/von-elrods-beer-hall-and-kitchen",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
      resourceGoals.GET_SMARTER_DATA,
    ],
  },
  {
    title: "A southern restaurant sees profits go north",
    imageName: "southern-table.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-02-28",
    href: "/case-studies/southern-table",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.GET_SMARTER_DATA,
      resourceGoals.MANAGE_AND_RETAIN_EMPLOYEES,
    ],
  },
  {
    title: "Fine dining reimagined",
    imageName: "imaginate-restaurant.png",
    industry: resourceIndustries.RESTAURANTS,
    tag: resourceTags.CASE_STUDY,
    date: "2022-03-21",
    href: "/case-studies/imaginate-restaurant",
    goals: [
      resourceGoals.STREAMLINE_OPERATIONS,
      resourceGoals.TAKE_PAYMENTS_EVERYWHERE,
    ],
  },
];
